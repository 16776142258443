@use "../../styles/common";

$menu-item-row-icon-size: 1.5rem;

.menuItemRow {
  display: flex;
  flex-direction: row;
  @include common.transition();
  @include common.interactive-menu-item;
  &__icon {
    width: 30px;
    display: flex;
    justify-content: center;
    margin-right: 10px;

    :global(svg) {
      width: $menu-item-row-icon-size;
      height: $menu-item-row-icon-size;
      font-size: common.$sidebar-menu-row-icon-size;
    }
  }
  &__title {
    user-select: none;
    align-self: center;
    padding-bottom: 2px;
  }
}