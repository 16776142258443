@use "../../../styles/common" as common;

$sticker-item-size: 100px;

.stickerItem {
  @include common.transition;
  width: $sticker-item-size;
  height: $sticker-item-size;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    box-shadow: common.$medium-highlight-box-shadow;
  }

  &__img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}