@use "../../styles/common";

$fullscreen-loader-text-size: common.$public-form-h2-size * 1.54;
$fullscreen-loader-spinner-color: common.$secondary;
$fullscreen-loader-spinner-size: $fullscreen-loader-text-size * 2;
$fullscreen-loader-title-spacing: common.$ct-font-size-base * 1.8;

.fullscreenLoader {
  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  &__spinner {
    color: $fullscreen-loader-spinner-color;
    width: $fullscreen-loader-spinner-size;
    height: $fullscreen-loader-spinner-size;
    margin-bottom: $fullscreen-loader-title-spacing;
  }
  &__title {
    user-select: none;
    font: {
      size: $fullscreen-loader-text-size;
      weight: common.$public-form-h2-weight;
    };
  }
}