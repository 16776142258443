@import "../../styles/common";

$color-input-indicator-size: $ct-font-size-base * 1.4285;
$color-input-saturation-width: 200px;
$color-input-saturation-height: $color-input-saturation-width * .75;
$color-input-hue-width: $color-input-saturation-width;
$color-input-hue-height: 10px;

.color-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  &__label {
    padding-right: $input-padding-x;
    margin-bottom: 0;
  }
  &__container {
    @include enable-focus-within;
    display: flex;
    flex-direction: row;
    cursor: text;
    padding: {
      top: 0;
      bottom: 0;
    };
    &.form-control input {
      outline: none;
      padding: {
        top: $input-padding-y;
        bottom: $input-padding-y;
        left: $input-padding-x;
      };
      border: none;
    }
  }
  &__circle-container {
    padding: {
      top: $input-padding-y;
      bottom: $input-padding-y;
      right: $input-padding-x;
    };
    &:not(.simplified) {
      border-right: 1px solid $ct-gray-250;
    }
  }
  &__circle {
    width: $color-input-indicator-size;
    height: $color-input-indicator-size;
    border-radius: 50%;
  }
  &__context {
    padding: 10px 10px;

    &_circles {
      left: 20px !important;
    }
  }
  &__saturation {
    position: relative;
    margin-bottom: 10px;
    width: $color-input-saturation-width;
    height: $color-input-saturation-height;
  }
  &__hue {
    position: relative;
    width: $color-input-hue-width;
    height: $color-input-hue-height;
  }

  &__circles {
    padding: 10px;
  }
}