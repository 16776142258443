@import "../../styles/common";

$title-font-size: $ct-font-size-base * 2.57;
$message-font-size: $ct-font-size-base * 1.57;
$content-width: 444px;

.welcome {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__title {
    font-size: $title-font-size;
    user-select: none;
    margin-bottom: 20px;
    max-width: $content-width;
    line-height: 1.15;
  }

  &__message {
    font-size: $message-font-size;
    user-select: none;
    max-width: $content-width;
    line-height: 1.15;
  }

  &__image {
    margin-top: 40px;
    user-select: none;
  }

  &__button {
    margin-top: 40px;
    min-width: 350px;
  }
}