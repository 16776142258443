@use "../../styles/common";

.messageItemLoader {
    display: flex;
    justify-content: flex-start;

    &_out {
        justify-content: flex-end;
    }

    &__body {
        width: 180px;
        height: 53px;
    }
}