@use "../../styles/common";

.imageUploadInput {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__btn {
    color: common.$primary;
  }
}