@import "../../styles/common";

$menu-user-min-width: 200px;
$menu-user-indicator-bg-color: $danger;
$menu-user-indicator-size: 16px;
$menu-user-indicator-font-size: $ct-font-size-base * .75;

.menu-item-user {
    &__context {
        min-width: $menu-user-min-width;
    }
    &__card {
        .user-card-introduction {
            font: {
                size: 16px;
            };
        }
    }
    &__notification-indicator {
        border-radius: 8px;
        background-color: $menu-user-indicator-bg-color;
        height: $menu-user-indicator-size;
        color: $body-bg;
        font-size: $menu-user-indicator-font-size;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3px;
    }
}