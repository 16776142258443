@import "../../styles/common";

.article-answer-item {
  margin-bottom: 15px;
  cursor: pointer;

  &__content {
    padding-top: 2px;
    min-width: 100px;
    flex: 8 1;
    word-break: break-word;
  }
  &__conditions {
    display: flex;
    flex-direction: row;
    &:not(:first-child) {
      margin-top: 5px;
    }
  }
  &__conditions-title {
    color: $primary;
    @include no-wrap-text();
    flex: 2 1
  }
  &__conditions-value {
    flex: 1 1
  }
  &__rest-list {
    margin-top: 5px;
  }
  &__toggle {
    margin-top: 5px;
  }
}