@use "../../styles/common" as common;

$height: 8px;
$height-half: $height / 2;
$handle-size: 16px;
$dot-size: 10px;
$track-color-active: rgba(16, 102, 168, 0.39);
$handle-color: common.$primary;

$color-inactive: common.$ct-gray-500;
$doc-color-inactive: #CCC;

.slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ($handle-size / 2) 15px;

    &__body {
        flex: 0 0 90%;

        :global(.rc-slider-handle) {
            width: $handle-size;
            height: $handle-size;
            margin-top: -($handle-size / 2 - $height-half);
            border-color: $handle-color;
            background-color: $handle-color;
        }

        :global(.rc-slider-dot-active) {
            border-color: $handle-color;
            background-color: $handle-color;
        }

        :global(.rc-slider-dot) {
            width: $dot-size;
            height: $dot-size;
            bottom: -($dot-size / 2)
        }
        :global(.rc-slider-track) {
            background-color: $track-color-active;
            height: $height;
        }

        :global(.rc-slider-rail) {
            height: $height;
        }

        :global(.rc-slider-mark-text) {
            white-space: nowrap;
        }

        :global(.rc-slider-disabled) {
            background-color: initial;

            :global(.rc-slider-track) {
                background-color: $color-inactive;
            }

            :global(.rc-slider-handle) {
                background-color: $doc-color-inactive;
                border-color: $color-inactive;
            }

            :global(.rc-slider-dot-active) {
                background-color: $doc-color-inactive;
                border-color: $color-inactive;
            }
        }
    }

    &__value {
        flex: 0 0 auto;
        margin-left: 15px;
    }
}