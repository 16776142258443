@use "../../styles/common" as common;

$tabs-font-size: common.$ct-font-size-base * 1.125;
$tabs-default-color: common.$ct-gray-650;

$tabs-active-color: common.$ct-gray-850;
$tabs-active-border-color: common.$ct-blue-4;

$tabs-invalid-color: common.$red;

.tabs {
    @include common.section-border;
}

.tab {
    @include common.no-outline-focus;
    font-size: $tabs-font-size;
    color: $tabs-default-color;
    margin-bottom: -1px;

    &__content {
        margin-top: 20px;
    }

    &.active {
        color: $tabs-active-color;
        border-bottom: 2px solid $tabs-active-border-color;
    }

    &.is-invalid {
        color: $tabs-invalid-color;

        &.active {
            border-bottom-color: $tabs-invalid-color;
        }
    }
}
