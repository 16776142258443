@import "../../styles/common";

.project-settings-form {
    .form-label {
        text-align: right;
        color: $ct-gray-650;
    }

    &__submit {
        margin-top: 40px;
    }

    .section {
        &__header {
            color: $ct-black-50;
            margin-bottom: 15px;
            font-size: 1rem;
        }

        &:not(last-child) {
            margin-bottom: 30px;
        }
    }

    .btn-submit {
        width: 120px;
    }

    .avatar-input {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .btn-link {
        &__icon {
            margin-right: 5px;
        }
    }

    &__download {
        margin-top: 20px;
    }
}