@import "../../../../styles/common";

$channel-node-header-bg-color: $ct-green-6;
$channel-node-width: 290px;

$channel-node-icon-size: 27px;
$channel-node-text-width: 160px;

.react-flow {
  .channel-node {
    border-radius: 5px;
    width: $channel-node-width;

    &__header {
      background-color: $channel-node-header-bg-color;
    }

    &__channel {
      display: flex;
      align-items: center;
    }

    &__content {
      background-color: $body-bg;
      padding: 20px 20px 26px 20px;
      min-height: 70px;
      border-radius: 0 0 5px 5px;
    }

    &__channel-content {
      display: flex;
      flex-direction: column;
      margin-left: 11px;
      line-height: 16px;
    }

    &__title {
      @include text-truncate;
      width: $channel-node-text-width;
      margin-bottom: 5px;
    }

    &__type {
      @include text-muted;
      width: $channel-node-text-width;
    }

    &__icon {
      width: $channel-node-icon-size;
      height: $channel-node-icon-size;
      border-radius: 50%;
      font-size: 16px;
      margin-right: 10px;
      margin-top: -5px;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: $channel-node-icon-size - 6px;
        width: fit-content;
      }

      svg, .msbot-icon, .teams-icon, .instagram-icon {
        height: 100%;
        width: fit-content;
      }
    }

    &__source-handle {
      right: -($scenario-node-handle-size / 2);
      background-color: $scenario-node-controls-color;
      height: $scenario-node-handle-size;
      width: $scenario-node-handle-size;
      bottom: 10px;
      top: auto;
      color: $ct-blue;

      &.connectable {
        cursor: pointer;
      }
    }
  }
}
