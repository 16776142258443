@import "../../../styles/common";

$sample-question-selected-color: $ct-blue;
$sample-question-control-color: $ct-gray-600;
$sample-question-control-hover-color: $ct-gray-175;

.sample-question {
  @include card-border;
  @include transition;
  display: flex;
  padding: 12px 16px 22px 18px;
  border-radius: $border-radius;
  cursor: pointer;

  &:hover {
    box-shadow: $medium-highlight-box-shadow;
  }

  &_selected {
    border: 1px solid $sample-question-selected-color;
  }

  &__content {
    overflow: hidden;
    word-wrap: break-word;
    padding-right: 12px;
    padding-top: 4px;
    flex: 1;
  }

  &__controls {
    @include transition;
    display: flex;
    opacity: 0;
  }

  &__control {
    @include transition;
    padding: 5px;
    border-radius: $border-radius;
    align-self: flex-start;
    color: $sample-question-control-color;
    cursor: pointer;

    &:hover {
      background-color: $sidebar-hover-color;
    }
  }

  &:hover {
    .sample-question {
      &__controls {
        opacity: 1;
      }
    }
  }
}