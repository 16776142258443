@import "../../styles/common";

$agent-type-form-icon-size: 38px;
$agent-type-form-header-font-size: $font-size-base * 1.14;
$agent-type-form-side-padding: 22px;

.agent-type-form {
  @include page-layout-sidebar();

  &__content {
    padding: 0 $agent-type-form-side-padding;
    height: 100%;
  }

  &__search-input {
    margin: 10px $agent-type-form-side-padding 16px $agent-type-form-side-padding;
  }

  &__accordion {
    border-bottom: none;

    .form-accordion__header {
      font-size: $agent-type-form-header-font-size;
      padding: 16px 0;
    }
  }

  .type-form-card {
    &__item {
      padding: 20px 16px;
    }

    &__icon {
      height: $agent-type-form-icon-size;
      width: $agent-type-form-icon-size;
      margin-top: 6px;
      font-size: 1.8rem;
    }
  }
}