@import "../../styles/common";

$notification-item-title-size: $ct-font-size-base * 1.143;
$notification-item-description-size: $ct-font-size-base;
$notification-item-date-size: $ct-font-size-base * 0.857;
$notification-item-hover-color: $ct-gray-150;
$notification-item-spinner-size: 16px;
$notification-invite-operator-color: $ct-orange-3;

@mixin set-notification-color($self, $color, $coloredTitle: false) {
  #{$self}__icon {
    color: $color;
  }
  @if $coloredTitle {
    #{$self}__title {
      color: $color;
    }
  }
}

.notificationItemContainer {
  display: flex;
  flex-direction: row;
  border-radius: $border-radius;
  box-shadow: $small-highlight-box-shadow;
  background-color: $body-bg;
  width: 100%;
  padding: 15px 0;
  align-items: center;
  justify-content: flex-start;
  $self: &;
  @include set-notification-color($self, $primary);
  &_error {
    @include set-notification-color($self, $danger, true);
  }
  &_success {
    @include set-notification-color($self, $success);
  }
  &_info {
    @include set-notification-color($self, $info);
  }
  &_inviteOperator {
    @include set-notification-color($self, $notification-invite-operator-color);
  }
  &_clickable {
    @include transition();
    cursor: pointer;
    &:hover {
      background-color: $notification-item-hover-color;
    }
  }
  &__payload {
    display: flex;
    flex-direction: row;
  }
  &__icon {
    margin: 0 18px;
    font-size: 30px;
    position: relative;
  }
  &__needApprove {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0;
    right: -4px;
    border-radius: 50%;
    border: 2px solid $body-bg;
    background-color: $danger;
    color: $body-bg;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-size: $notification-item-title-size;
    font-weight: bold;
  }
  &__description {
    font-size: $notification-item-description-size;
  }
  &__date {
    font-size: $notification-item-date-size;
    color: $text-muted;
  }
  &__actions {
    margin-right: 18px;
    margin-left: auto;

  }
  &__close {
    margin: {
      left: auto;
      right: 6px;
    };
  }
  &__content {}
  &__spinnerContainer {
    padding: 8px 10px;
    .spinner {
      width: $notification-item-spinner-size;
      height: $notification-item-spinner-size;
    }
  }
  &__buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    row-gap: 10px;
    margin-right: 16px;
  }
}