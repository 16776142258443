@use "../../styles/common";

$wizard-view-body-margin-top: 240px;
$wizard-view-img-height: 220px;

.wizardView {
  height: 100%;
  margin-left: -(common.$wizard-sidebar-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__body {
    padding: 0;
  }
  &__text {
    margin-bottom: common.$form-group-margin-bottom;
  }
  &__formContainer {
    @media (min-width: 1200px) and (max-width: 1560px) {
      flex: 0 0 40%;
      min-width: 40%;
    }
    @media (min-width: 2500px) {
      flex: 0 0 15%;
      min-width: 15%;
    }
  }
  &__img {
    height: $wizard-view-img-height;
  }
}