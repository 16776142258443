@import "../../../styles/common";

.slot-form {
  @include page-layout-sidebar;
  @include sidebar-form;

  &__textarea {
    textarea {
      min-height: 80px;
    }
  }

  .custom-checkbox {
    margin-bottom: 1rem;
  }
}