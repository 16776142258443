@import "../../styles/common";

.article-actions {
  display: flex;
  margin-left: auto;

  &__action {
    @include article-action();

    &_main {
      margin-left: 10px;
    }

    &_publish, &_save {
      width: 130px;
      background-color: $knowledge-base-primary-text;
    }

    &_edit {
      width: 100px;
      border-color: $knowledge-base-primary-text;
    }

    &_additional {
      color: $knowledge-base-main-text;

      &:hover {
        color: $knowledge-base-primary-text;
      }
    }

    &_error.btn {
      color: $danger;

      &:hover {
        color: $danger;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__icon {
    margin-right: 10px;

    &_single {
      margin-right: 0;
    }
  }
}
