@import "../../../../styles/common";

$article-node-header-bg-color: $ct-red-5;
$article-node-width: 350px;

.react-flow {
  .article-node {
    width: $article-node-width;
    border-radius: 5px;

    &__header {
      background-color: $article-node-header-bg-color;
      border-radius: 5px 0 0 0;
    }

    &__content {
      background-color: $body-bg;
      padding: 20px 48px 16px;
      min-height: 70px;
      border-radius: 0 0 5px 5px;
    }

    &__link {
      @include mini-scrollbar();

      width: 100%;
      height: 95px;
      border: none;
      resize: none;
      outline: none;
      padding: 0;

      text-decoration: underline;
      color: $primary;
      font-size: 16px;
      line-height: 24px;
    }
  }
}