@import "../../styles/common";

.context-menu {
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $highlight-box-shadow;
    padding: {
        top: 10px;
        bottom: 10px;
        left: 5px;
        right: 5px;
    };
    z-index: 8000;
    &__container {
        cursor: pointer;
        width: 100%;
    }
}