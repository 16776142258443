@use "../../styles/common";

$btn-color: common.$ct-black-50;
$hover-color: common.$ct-gray-500;
$active-color: common.$ct-gray-650;

.copyInput {
    border-right: none;
}

.copyInputControl {
    $self: &;
    color: $btn-color;
    background-color: common.$gray-200;
    border-color: common.$input-border-color;
    border-left: none;

    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &:hover {
        color: $btn-color;
        background-color: $hover-color;
        border-color: $hover-color;
    }

    &:not(:disabled):not(.disabled):active {
        color: $btn-color;
        background-color: $active-color;
        border-color: $active-color;
    }

    &__icon {
        @include common.transition-all;
        font-size: common.$ct-font-size-base * 1.14;
    }
}
