
.checkbox-input {
  input, label {
    cursor: pointer;
  }

  &__label {
    display: flex;
    align-items: center;
  }
}