@import "../../styles/common";

$sidebar-context-menu-select-width: 180px;
$sidebar-context-menu-select-font-size: $ct-font-size-base;
$sidebar-context-menu-select-toggle-icon-size: $ct-font-size-base * 1.43;

.sidebar-context-menu-select {
  width: auto;
  display: flex;

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: $sidebar-context-menu-select-width;
  }

  &__selected-item {
    @include text-truncate();
    padding-right: 10px;
    line-height: 30px;
    font-size: $sidebar-context-menu-select-font-size;
  }

  &__icon {
    display: flex;
  }

  &__icon-toggle {
    font-size: $sidebar-context-menu-select-toggle-icon-size;
  }

  &__menu {
    @include mini-scrollbar();

    max-height: 250px;
    overflow-y: auto;
  }

  &__item {
    .menu-item-row__title {
      @include text-truncate();
      width: $sidebar-context-menu-select-width - 10px;
    }
  }
}