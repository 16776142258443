@use "../../styles/common";

$item-color: common.$ct-gray-650;
$item-hover-color: darken($item-color, 10%);
$loading-size: 15px;

.searchInput {
  flex: 1 1 auto;
  position: relative;
  $self: &;

  &__optionContainer {
    color: $item-color;
    cursor: pointer;
    @include common.transition-all();
    &:hover {
      color: $item-hover-color;
    }
  }

  &__iconPosition {
    position: absolute;
    &_left {
      left: 17px;
      top: 7px;
    }
    &_right {
      right: 17px;
      top: 9px;
    }
  }

  &__label {
    margin-left: 5px;
  }

  &__input {
    padding-left: 38px;
    padding-right: 35px;
    &_withLabel {
      padding-left: 120px;
    }
  }

  &__loading {
    width: $loading-size;
    height: $loading-size;
  }

  &__clear {
    cursor: pointer;
    color: $item-color;
    @include common.transition-all();
    #{$self}__icon {
      font-size: 18px;
    }
    &:hover {
      color: $item-hover-color;
    }
  }
}