@import "../../styles/common";

$tag-border-color: $primary;

.tag {
    display: inline-flex;
    padding: 2px 8px;
    justify-content: space-between;
    align-items: center;
    font-size: $ct-font-size-base;
    border-radius: 6px;
    border: 1px solid $tag-border-color;
    margin-top: 5px;
    $self: &;

    &__title {
        max-width: 100px;
        word-wrap: break-word;
    }

    &__close {
        margin-left: 5px;
        cursor: pointer;
    }

    &_is-invalid {
        border-color: $red;

        #{$self}__icon {
            color: $red;
        }
    }

    &__icon {
        composes: tagIconRemove from global;
        color: $tag-border-color;

        &:hover {
            cursor: pointer;
        }
    }
}
