@use '../../styles/common' as common;

.creatableTagSelect {
  margin-bottom: .5rem;

  &__tags {
    margin-bottom: 1rem;
  }

  &__tag {
    @include common.selectedTag;
  }

  &__tagInput {
    :global(.custom-select__indicators) {
      display: none;
    }
  }
}