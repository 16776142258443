@import "../../styles/common";

.knowledge-base-permissions {
  max-height: 285px;
  margin-bottom: 20px;
  overflow-y: auto;

  &__item {
    margin-bottom: 15px;
  }
}
