@import "../../styles/common";

.article-comment-thread-view {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  $self: &;
  &__replies {
    display: flex;
    flex-direction: column;
  }
  &_loader {
    #{$self}__input-container {
      width: 20%;
      height: 16px;
    }
  }
}