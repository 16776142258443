@import "../../styles/common";

.project-card {
    border-radius: $border-radius;
    transition: $transition-base;
    display: flex;
    flex-direction: row;
    align-items: center;
    $self: &;
    &_short {
        flex-direction: column;
        #{$self}__image {
            margin-right: 0;
        }
        #{$self}__name {
            font-size: $menu-item-title-font-size;
        }
    }
    &__image {
        @include fit-image;
        border-radius: $border-radius;
        border: 1px solid $gray-400;
        height: $project-image-height;
        width: $project-image-weight;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
    &__name {
        font-size: $ct-font-size-base;
        @include no-wrap-text("clip");
    }
    &__name-item {
        user-select: none;
    }
}