@import "styles/fonts";
@import "styles/animations";
@import "styles/common";
@import "styles/spins";
@import "styles/scrollbar";
@import "~bootstrap/scss/utilities";
@import "styles/bootstrap-components";
@import "styles/bootstrap-component-overrides";

// bootstrap
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";

@import '~react-datepicker/dist/react-datepicker.css';

.hidden {
  display: none;
}

.ctBody {
  overflow: hidden;

  &_scroll {
    overflow: auto;
  }
}
