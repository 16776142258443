@import "../../styles/common";

$logo-icon-size: 26px;
$card-header-font-size: $ct-font-size-base;
$card-header-bg-color: $ct-gray-300;

.info-card {
  @include card-border;
  min-height: 100px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;

  &.selected {
    box-shadow: $medium-highlight-box-shadow;
  }

  &__header {
    border-radius: 4px 4px 0 0;
    min-height: 20px;
    padding: 3px 16px;
    background-color: $card-header-bg-color;
    font-size: $card-header-font-size;
    user-select: none;
  }

  &__btn-settings {
    cursor: pointer;
  }

  &__body {
    display: flex;
    padding: 16px;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    line-height: 16px;
  }

  &__title {
    @include no-wrap-text("ellipsis");
    padding-bottom: 6px;
    width: 160px;

    &:empty::before {
      content: "\200b";
    }
  }

  &__type {
    @include text-muted;
  }

  &__icon {
    align-self: flex-start;
    margin-right: 11px;
  }
}