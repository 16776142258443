@use "../../styles/common";

$duration-max-width: 110px;

.operatorCard {
    cursor: pointer;
    &__status {
        display: flex;
        flex-direction: row;
    }
    &__item {
        display: inline-block;
        padding: 0 4px;
        margin-right: 5px;
    }
    &__duration {
        @include common.no-wrap-text();
        font-size: common.$menu-item-title-font-size;
        color: common.$text-muted;
        max-width: $duration-max-width
    }
}