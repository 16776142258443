@import "../../styles/common";

$channel-logo-icon-size: 36px;

.channel-form-header {
  @include form-header-with-toggle;

  &__logo {
    width: $channel-logo-icon-size;
    height: $channel-logo-icon-size;
    color: $primary;
  }

  svg, .msbot-icon, .teams-icon, .instagram-icon {
    width: fit-content;
    height: 100%;
  }

  img {
    width: $channel-logo-icon-size - 6px;
    height: $channel-logo-icon-size - 6px;
  }
}