@import "../../styles/common";

$header-color: $ct-gray-175;
$article-border-color: $ct-gray-300;
$articles-font-size: $ct-font-size-base;
$article-border-color: $ct-gray-300;

.searchQueryArticles {
  &__header {
    background-color: $header-color;
    line-height: 25px;
    padding: 0 15px;
    margin-right: 15px;
    color: $knowledge-base-sub-text;
    font-size: $articles-font-size;
  }

  &__body {
    padding: 0 15px;
  }
}