@import "../../styles/common";

.project-settings {
    &__header {
        margin-bottom: 30px;
    }

    &__content {
        padding: 44px 40px 30px;
    }

    &__title {
        font-size: $page-title-font-size;
        user-select: none;
    }

    &__nav-link {
        color: $ct-black-50;

        &:hover {
            text-decoration: none;
        }

        .nav-link {
            &__menu-item {
                padding: 10px 17px;
                border-radius: 0;

                &_sub-item {
                    padding-left: 52px;
                }
            }
        }
    }
}