@import "../../../../../styles/common";

$keyboard-button-color-negative: $ct-red-4;
$keyboard-button-color-negative-active: $ct-red-1;
$keyboard-button-color-secondary: $ct-blue-12;
$keyboard-button-text-color: $ct-blue;
$keyboard-button-popover-width: 450px;
$keyboard-button-settings-buttons-width: 50px;
$keyboard-button-settings-button-size: 0.9 * $ct-font-size-base;

.keyboard-button {
  flex: 1;
  font-size: $knowledge-base-article-text-font-size;

  &__btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: $keyboard-button-settings-buttons-width;
  }

  &_primary {
    .keyboard-button {
      &__btn {
        background-color: $body-bg;
      }
    }
  }

  &_negative {
    .keyboard-button {
      &__btn {
        @include no-outline-focus;
        background-color: $keyboard-button-color-negative;
        border-color: $keyboard-button-color-negative;

        &:active:not(:disabled) {
          background-color: $keyboard-button-color-negative-active;
          border-color: $keyboard-button-color-negative-active;
        }
      }

      &__drag-icon, &__settings-btn, &__content, &__textarea {
        color: $body-bg;
      }
    }
  }

  &_secondary {
    .keyboard-button {
      &__btn {
        background-color: $keyboard-button-color-secondary;
      }
    }
  }

  &__content, &__textarea {
    @include mini-scrollbar(2px);
    @include no-outline-focus;
    text-align: center;
    flex-basis: calc(100% - #{$keyboard-button-settings-buttons-width});
    border: none;
    resize: none;
    color: $keyboard-button-text-color;
    background-color: inherit;
    padding: 2px 0;
  }

  &__textarea {
    &_hidden {
      display: none;
    }
  }

  &__drag-icon {
    @include transition();
    cursor: grab;
    color: $knowledge-base-sub-text;
    font-size: 1rem;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
  }

  &__settings-buttons {
    margin-left: auto;
    display: none;
  }

  &__settings-btn {
    @include transition();
    padding: 0;
    color: $knowledge-base-sub-text;
    font-size: $keyboard-button-settings-button-size;
    opacity: 0;
    pointer-events: none;
    margin-left: 10px;
  }

  &__popover {
    max-width: $keyboard-button-popover-width;
    width: $keyboard-button-popover-width;
    font-size: $knowledge-base-form-font-size;

    .arrow {
      display: none !important;
    }
  }

  &:not(.keyboard-button_disabled) {
    .keyboard-button {
      &__settings-buttons {
        display: flex;
      }
    }

    &:hover {
      .keyboard-button {
        &__settings-btn, &__drag-icon {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  &_disabled {
    .keyboard-button {
      &__content {
        pointer-events: none;
      }
    }
  }
}