@import "../../../../../styles/common";

$condition-node-line-length: 20px;
$condition-node-item-width: 305px;

.scenario-editor {
  .condition-node-list {
    position: absolute;
    padding: 12px 0 10px 0;
    margin-left: 20px;

    &:before, &:after {
      content: "";
      position: absolute;
    }

    &:before {
      left: -1px;
      top: 0;
      bottom: 0;
      background-color: $scenario-node-controls-color;
      width: $scenario-edge-width;
    }

    & > div:nth-last-child(2) {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        visibility: hidden;
        left: -$scenario-edge-width;
        height: 50%;
        bottom: $scenario-edge-width;
        background-color: $body-bg;
        width: $scenario-edge-width * 2;
      }
    }

    &__item {
      @include transition-all;
      margin-left: 19px;
      width: $condition-node-item-width;

      &:before {
        content: "";
        position: absolute;
        left: -$condition-node-line-length;
        top: 50%;
        height: $scenario-edge-width;
        background-color: $scenario-node-controls-color;
        width: $condition-node-line-length;
      }

      &_selected {
        box-shadow: $medium-highlight-box-shadow;
      }
    }

    &__add-btn {
      margin: 10px 0 0 -12px;
      position: absolute;

      &_hidden {
        visibility: hidden;
        pointer-events: none;
      }

      &:before {
        content: "";
        position: absolute;
        left: calc(50% - 1px);
        background-color: $scenario-node-controls-color;
        width: $scenario-edge-width;
      }
    }

  }

  &_not-editable {
    .condition-node-list {
      > div:nth-last-child(2):after {
        visibility: visible;
      }
    }
  }
}

.condition-node-list {
  &__clone {
    @include card-border();
    box-sizing: content-box;
    min-height: 32px;
    background-color: $body-bg;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;

    display: flex;
    justify-content: center;
    flex-direction: column;

    max-width: $condition-node-item-width;
    padding: 2px 30px 4px;
  }
}