@import "../../styles/common";

$title-font-size: $font-size-base * 1.14;

.add-agent-block-form {
  @include page-layout-sidebar();
  $self: &;

  &__content {
    padding: 10px 22px 0;
  }

  &__agent-types-title {
    padding-bottom: 24px;
    font-size: $title-font-size;
  }

  &__agent-types {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
}
