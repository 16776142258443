@import "../../styles/common";

.project-view {
    background-color: $main-container-background;
    padding-left: $sidebar-width;
    @include transition-all();
    &_sidebar-opened {
        padding-left: $sidebar-opened-width;
    }
    &__sidebar {

    }
    &__content {
        min-height: 100vh;
        overflow-y: hidden;
    }
    &__container {

    }
    &__row {

    }
}