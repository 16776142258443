@import "../../styles/common";

.agents {
  @include tab-page;
  height: 100%;
  overflow: visible;

  &__title {
    +svg {
      margin-bottom: 1px;
      cursor: pointer;
    }
  }
}