@import "../../../styles/common";

$metric-value-positive-diff-color: $ct-green-7;
$metric-value-negative-diff-color: $ct-red-3;
$metric-value-font-size: $ct-font-size-base * 0.93;

.classifier-training-history {
  @include card-border;
  @include transition;

  border-radius: 5px;
  margin-bottom: 16px;
  padding: 12px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &_current {
    box-shadow: $medium-highlight-box-shadow;
  }

  &__status {
    padding: 2px 8px;
    background-color: $ct-blue-10;
    border-radius: 4px;
    margin-left: 5px;
  }

  &__settings {
    margin-left: 10px;
  }

  &__nums-icon {
    margin: 0 6px 0 16px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  &__metrics {
    @include text-muted;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;

    .info-icon {
      margin-left: 6px;
    }
  }

  &__metric {
    margin-right: 10px;
  }

  &__metric-value {
    color: $ct-black-50;
    margin-left: 6px;
    font-weight: 500;
    font-size: $metric-value-font-size;
  }

  &__metric-diff {
    margin-left: 6px;
    font-size: $metric-value-font-size;

    &_positive {
      color: $metric-value-positive-diff-color;
    }

    &_negative {
      color: $metric-value-negative-diff-color;
    }
  }

  &__matrices {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  &__matrix {
    @include button-icon;
    @include transition();
    padding: 0;
    margin-right: 8px;
    font-size: 18px;
  }

  &__popover {
    min-width: 60%;
    height: 80%;

    .popover-body {
      height: 100%;
    }
  }

  &__initiator {
    margin-bottom: 16px;
  }

  &__initiator-icon {
    margin-top: 4px;
    margin-right: 7px;
  }
}