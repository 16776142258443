@import "../../styles/common";

$article-view-bottom-spacing: 200px;

.article-view {
  @include article-view;

  &__body {
    margin-bottom: $article-view-bottom-spacing;
  }

  &__read-confirmation {
    @include article-section;
    display: flex;
    justify-content: center;
  }
}