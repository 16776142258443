@import "../../styles/common";

$form-accordion-header-font-size: $font-size-base * 1.14;

.form-accordion {
    @include section-border;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 12px 0;
        font-size: $form-accordion-header-font-size;

        &_lg {
            padding: 16px 0;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        &_disabled {
            color: $ct-gray-450;
        }
    }

    &__toggle {
        margin-left: 20px;
    }

    &__header-element {
        margin-left: auto;
    }

    &__spinner {
        width: $loading-spinner-size;
        height: $loading-spinner-size;
        color: $primary;
        margin-left: 10px;
    }

    &.is-invalid {
        border-bottom-color: $red;

        .form-accordion__header {
            color: $red;
        }
    }
}
