@import "../../../styles/common";

$classifier-logo-icon-size: 36px;

.classifier-form-header {
  @include form-header-with-toggle;

  &__icon {
    width: $classifier-logo-icon-size;
    height: $classifier-logo-icon-size;
  }

  &__status-container {
    position: relative;
    margin-bottom: 1rem;
  }

  &__status {
    position: absolute;
    top: -.5rem;
  }
}