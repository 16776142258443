// defined interface colors. Add your color here if it is used in more than one element.

//menu
$sidebar-hover-color: $ct-gray-150;
$sidebar-selected-color: $primary;

$sidebar-divider-color: $ct-gray-300;
$sidebar-divider: 1px solid $sidebar-divider-color;

// main section
$main-container-background: $white;

$text-on-primary: $white;
$sub-text-color: $gray-500;

// loading gradient
$loading-background: linear-gradient(270deg, $gray-200, $ct-gray-50, $gray-300);

//channel colors
$web-chat: $ct-orange;
$web-chat-mobile: $ct-orange-1;
$telegram: $ct-blue-1;
$viber: $ct-purple-1;
$skype: $ct-blue-2;
$email: $ct-red-1;
$whatsapp: $ct-green-2;
$phone: $ct-green-1;
$test: $ct-gray-850;
$unknown: $ct-gray-650;
$vk: #2787f5;
$odnoklassniki: #f58220;
$facebook: #1877f2;
$msbot: #464775;
$teams: #464775;
$yandex-alisa: #6143c7;
$yandex-dialogs: #ffcc00;

$channel-colors: (
    "web-chat": $web-chat,
    "web-chat-mobile": $web-chat-mobile,
    "telegram": $telegram,
    "viber": $viber,
    "skype": $skype,
    "email": $email,
    "whatsapp": $whatsapp,
    "phone": $phone,
    "test": $test,
    "unknown": $unknown,
    "vk": $vk,
    "odnoklassniki": $odnoklassniki,
    "facebook": $facebook,
    "ms-bot": $msbot,
    "teams": $teams,
    "yandex-alisa": $yandex-alisa,
    "yandex-dialogs": $yandex-dialogs
);

// operator status colors
$online: $ct-green-3;
$offline: $ct-red-2;
$lunch: $ct-orange-2;
$training: $ct-blue-3;
$invisible: $ct-gray-650;

$operator-status-colors: (
    "online": $online,
    "offline": $offline,
    "lunch": $lunch,
    "training": $training,
    "donotroute": $invisible
);

$file-colors: (
    "doc": $ct-blue-4,
    "xls": $ct-green-1,
    "pdf": $ct-red-3,
    "unknown": $ct-gray-650
);

$knowledge-base-main-text: $ct-black-50;
$knowledge-base-sub-text: $ct-gray-600;
$knowledge-base-primary-text: $primary;

$knowledge-base-status: (
    "active": $ct-green-2,
    "inactive": $ct-orange-2,
    "removed": $ct-red-2,
    "draft": #c4c4c4,
    "approval": $ct-blue-3
);

$scenario-node-controls-color: $gray-500;
$scenario-node-target-handle-color: transparentize(#c6ddef, 0.5);
