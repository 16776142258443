@import "../../../../styles/common";

$message-node-buttons-list-item-height: 32px;

.buttons-list-item {
  background-color: transparent;
  padding:  0 48px 8px 48px;

  &__btn {
    @include card-border();
    position: relative;
    min-height: $message-node-buttons-list-item-height;
    background-color: $body-bg;
    border-radius: 5px;
    padding: 2px 0 4px;
    text-align: center;
    font-weight: 500;

    display: flex;
    align-items: center;

    .btn-close {
      @include transition-all();

      opacity: 0;
      pointer-events: none;
    }
  }

  &__input {
    @include mini-scrollbar;
    text-align: center;
    font-weight: 500;
    color: $primary;
    border: none;
    flex-basis: 75%;
    margin-left: 35px;
    resize: none;

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: inherit;
    }
  }

  &__drag-icon {
    @include no-outline-focus();

    float: left;
    padding: 0 8px;
    margin-left: 5px;
    margin-right: -29px;
    color: $scenario-node-controls-color;
    font-size: 1rem;
    z-index: 1;
  }

  .btn-close {
    padding: 0;
    margin-right: 12px;
    margin-left: auto;
    color: $scenario-node-controls-color;
    font-size: 0.8rem;
  }
}
