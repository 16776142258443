@import "../../styles/common";

.invite-users-form {
    @include page-layout-sidebar();

    &__name {
        color: $ct-gray-650;
    }

    &__description {
        color: $ct-gray-850;
    }
}