@import "../../../styles/common";

$classifier-form-stop-button-color: $ct-red-4;

.classifier-form {
  @include page-layout-sidebar();
  @include sidebar-form();

  &__content {
    padding-bottom: 30px;
  }

  &__message {
    text-align: center;
  }

  .custom-checkbox {
    margin-bottom: 1rem;
  }

  &__tag {
    @include selectedTag;
  }

  &__tags {
    margin-bottom: 1rem;
  }

  &__tag-input {
    .custom-select__indicators {
      display: none;
    }
  }

  &__tag-form-group {
    margin-bottom: .5rem;
  }

  &__train-buttons {
    display: flex;
  }

  &__train-button {
    flex: 1;

    &:first-of-type {
      margin-right: 20px;
    }

    &_stop {
      color: $classifier-form-stop-button-color !important;
    }
  }

  &__training-status-section {
    margin-top: 10px;
  }

  &__training-status {
    margin-left: 20px;
  }
}