@import "../../../../styles/common";

$start-node-bg-color: $ct-blue-7;
$start-node-size: 60px;

.react-flow {
  .start-node {
    width: $start-node-size;
    height: $start-node-size;
    background-color: $start-node-bg-color;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    &__handle {
      left: 0;
      background-color: initial;
      right: 0;
      width: 100%;
      height: 100%;

      &.connectable {
        cursor: pointer;
      }
    }

    text {
      display: block;
      margin-bottom: 2px;
    }
  }
}