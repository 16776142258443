@use "../../../../styles/common";

.convertTemplateControl {
  &__row {
    align-items: center;
    justify-content: space-between;
  }

  &__iconControl {
    padding: 0.5rem 9px;
  }

  &__icon {
    padding: 0;
  }

  &__fakeLink {
    color: common.$primary;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__upload {
    flex-direction: row-reverse;
  }

  &__spinner {
   :global(.spinner) {
     width: common.$loading-spinner-size;
     height: common.$loading-spinner-size;
   }
  }
}
