@import "../../styles/common";

.agent-form-header {
  &__content {
    padding: 10px 0 30px;
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 28px;
    margin-right: 11px;
    color: $primary;

    svg {
      font-size: 1.8rem;
    }
  }
}
