@use "../../styles/common";

.textarea-info-loader {
  height: 170px;

  &.is-invalid {
    color: common.$ct-red-3;
  }
}

.textarea-info {
  min-height: 170px;
  resize: none;
}