@import "../../styles/common";

$info-icon-color: $ct-gray-500;

.channel-form {
  @include page-layout-sidebar();
  @include sidebar-form();

  .custom-checkbox {
    margin-bottom: 1rem;
  }

  &__radio::after {
    width: 18px;
    height: 18px;
  }

  &__color-circle {
    .color-circle-input__circle {
      width: 25px;
      height: 25px;
    }
  }

  &__color-input {
    margin-top: -0.3rem;

    &_border {
      .color-input__circle-container {
        border-right: 1px solid $border-color;
      }
    }
  }

  &__footer-accordion {
    border-top: 1px solid $ct-gray-450;
    border-bottom: none;
    padding: 0 7px;

    .form-accordion {
      &__title {
        font-size: $font-size-base;
      }

      &__body:last-child {
        margin-bottom: 12px;
      }
    }
  }

  &__dropdown {
    z-index: 3000;
  }

  textarea {
    resize: none;
  }

  &__textarea {
    min-height: 64px;
  }

  &__message {
    textarea {
      min-height: 100px;
    }
  }

  &__test-chat-button {
    margin-bottom: 10px;
    width: 100%;
  }
}