@import "../../../../styles/common";

$command-node-header-bg-color: #ebe5f0;
$command-node-width: 290px;
$command-node-title-font-size: $font-size-base * 1.14;

.react-flow {
  .command-node {
    width: $command-node-width;
    border-radius: 5px;

    &__header {
      border-radius: 5px 0 0 0;
      background-color: $command-node-header-bg-color;
    }

    &__content {
      background-color: $body-bg;
      padding: 20px 20px 24px 20px;
      min-height: 70px;
      border-radius: 0 0 5px 5px;
    }

    &__title {
      font-size: $command-node-title-font-size;
      line-height: 18px;
      margin-bottom: 6px;
    }

    &__handle {
      @include scenario-handle;
      bottom: 10px;
      top: auto;
    }
  }
}