@use "../../styles/common";

$custom-select-spinner-color: common.$gray-500;
$custom-select-option-color-on-hover: common.$ct-gray-175;

.custom-select {
  &__value-container {
    padding: common.$input-padding-y common.$input-padding-x !important;
    height: calc(#{common.$input-height} - 2px);
  }

  &__control {
    @include common.enable-focus-within;
    outline: 0 none;
    min-height: 35px !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    padding: 0 !important;
  }

  &__indicators {
    height: 33px !important;
  }

  &__dropdown-indicator {
    color: common.$input-color !important;
    width: 17px;
  }

  &__value-container > div {
    padding-top: 0;
    padding-bottom: 1px;
    margin: 0;
  }

  &__menu {
    z-index: 4 !important;
  }

  &__option {
    line-height: 12px;
    cursor: pointer;

    &:hover, &--is-focused {
      background-color: $custom-select-option-color-on-hover !important;
    }

    &--is-selected {
      background-color: common.$white !important;
      color: inherit !important;
    }
  }

  &__spinner {
    margin-right: 8px;
    color: $custom-select-spinner-color;
  }

  &_invalid {
    & > div {
      @extend .is-invalid;
      border-color: common.$red;
    }
  }

  &_hide-dropdown-indicator {
    .custom-select__dropdown-indicator {
      display: none;
    }
  }
}