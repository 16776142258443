@use "../../../styles/common";

.formContent {
  width: common.$sidebar-right-width;
  margin-top: 1rem;

  :global(.checkbox-input) {
    margin-bottom: 1rem;
  }

  :global(textarea) {
    resize: none;
    min-height: 80px;
  }
}