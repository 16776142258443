@use "../../styles/common" as common;

$notification-badge-bg-color: common.$primary;
$notification-badge-size: 16px;
$notification-badge-font-size: 0.78 * common.$font-size-base;

.projectSettingsContainer {
  @include common.tab-page;

  &__section {
    margin-top: 1rem;

    &_border {
      @include common.section-border;
    }
  }

  &__notifications {
    margin-left: 6px;
    width: $notification-badge-size;
    height: $notification-badge-size;
    font-size: $notification-badge-font-size;
    border-radius: 50%;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: $notification-badge-bg-color;
    background-color: common.$white;
  }
}
