@import "../../styles/common";

.settingsContextMenuItem {
    display: flex;
    @include interactive-menu-item;
    padding: 8px 16px;
    border-radius: 0;
    @include transition();

    &:hover {
        background-color: $sidebar-hover-color;
    }

    &_separated {
        border-top: 1px solid $ct-gray-300;
    }

    &_danger {
        color: $ct-red-4;
    }

    &_disabled {
        color: $text-muted;
    }

    &__icon {
        margin-right: 10px;
    }
}