@import "../../styles/common";

.role-form {
    @include page-layout-sidebar();

    &__section {
        @include form-section-padding;
    }

    &__name {
        color: $ct-gray-650;
    }

    &__description {
        color: $ct-gray-850;
    }

    &__section {
        padding-top: 20px;
    }
}