@import "../../../../../styles/common";

$conditions-node-header-bg-color: $ct-blue-8;
$condition-node-header-width: 305px;

$conditions-node-handle-size: 30px;
$condition-node-line-length: 20px;
$condition-node-add-line-length: 35px;

.scenario-editor {
  .conditions-node {
    &_selected {
      .conditions-node {
        &__header {
          box-shadow: $medium-highlight-box-shadow;
        }
      }

      .btn-close {
        opacity: 1;
        pointer-events: initial;
      }
    }

    &__header {
      @include transition-all;
      width: $condition-node-header-width;
      background-color: $conditions-node-header-bg-color;
      margin-left: 39px;

      &:before {
        content: "";
        position: absolute;
        left: $condition-node-line-length;
        top: 25%;
        height: $scenario-edge-width;
        background-color: $scenario-node-controls-color;
        width: $condition-node-line-length;
      }

      &:after {
        content: "";
        position: absolute;
        left: $condition-node-line-length - 1px;
        top: 25%;
        height: 15px;
        background-color: $scenario-node-controls-color;
        width: $scenario-edge-width;
      }

      &_empty {
        &:after, &:before {
          content: none;
        }
      }
    }

    &__target-handle {
      @include transition-all(.3s);
      top: -$scenario-node-target-handle-size / 2;
      background-color: initial;
      border: none;
      border-radius: 50%;
      height: $scenario-node-target-handle-size;
      width: $scenario-node-target-handle-size;
      margin-left: 30px;

      &.connectable {
        cursor: pointer;
      }
    }

    &__item {
      margin-bottom: 8px;
    }

    &__condition-list {
      &:before {
        height: calc(100% - 32px);
      }

      &_connectable {
        &:before {
          height: calc(100% - 35px);
        }
      }

      .condition-node-list {
        &__add-btn:before {
          top: -$condition-node-add-line-length;
          height: $condition-node-add-line-length;
        }
      }
    }
  }

  &_connecting {
    .conditions-node {
      &__target-handle {
        background-color: $scenario-node-target-handle-color;
      }
    }
  }
}