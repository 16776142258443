@import "../../styles/common";

$title-font-size: $ct-font-size-base * 1.57;
$message-font-size: $ct-font-size-base * 1.14;

.mobile-lock-screen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 30px 79px;

  &__icon {
    color: $primary;
    margin-bottom: 32px;
    font-size: $ct-font-size-base * 4.57;
  }

  &__title {
    font-size: $title-font-size;
  }

  &__message {
    font-size: $message-font-size;
  }
}