@import "../../styles/common";

$title-font-size: $ct-font-size-base * 1.43;

.calendar-modal {
  margin-top: 10%;
  max-width: 710px;

  .modal-content {
    border-radius: 5px;
  }

  .modal-body {
    padding: 20px;
    height: 360px;
  }

  .modal-title {
    font-weight: 500;
    font-size: $title-font-size;
    line-height: 22px;
  }

  .modal-footer {
    padding: 30px;
  }

  &__content {
    display: flex;
    width: 100%;
    height: 360px;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;

    width: 152px;
    border-right: $sidebar-divider;
    padding: 20px 20px 10px 20px;
  }

  &__date-picker {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 50px;
  }
}

.react-datepicker {
  font-family: inherit;
  font-size: $font-size-base;
  border: none;

  &__header {
    background-color: transparent;
    border: none;

  }

  &__day-name {
    color: $ct-gray-650;
    text-transform: capitalize;
  }

  &__current-month {
    font-weight: normal;
    padding-bottom: 10px;
    text-transform: capitalize;
  }

  &__navigation:focus {
    outline: none;
  }

  &__month-container {
    + .react-datepicker__month-container {
      margin-left: 10px;
    }
  }

  &__day {
    &--outside-month {
      color: $ct-gray-650;
    }
  }

  &__navigation {
    border: 1px solid transparent;
    width: 8px;
    height: 8px;
    border-top-color: $ct-black-50;
    border-right-color: $ct-black-50;
    top: 15px;

    &--next {
      transform: rotate(45deg);

      &:hover {
        border-left-color: transparent;
      }
    }

    &--previous {
      transform: rotate(-135deg);

      &:hover {
        border-right-color: $ct-black-50;
      }
    }
  }
}