@import "../../styles/common";

.channel-list {
  &__card {
    width: 210px;
  }
}

.channel-card {
  &__icon {
    margin-top: -2px;
  }
}
