@import "../../styles/common";

$sidebar-side-padding: 5px;
$sidebar-main-color: $ct-gray-100;
$sidebar-inner-color: $ct-gray-50;
$sidebar-horizontal-padding: $sidebar-vertical-padding * .8;

@mixin sidebar-padding {
    padding: {
        left: $sidebar-side-padding;
        right: $sidebar-side-padding;
    }
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: $sidebar-width;
    overflow: hidden;
    border-right: $sidebar-divider;
    padding: {
        top: $sidebar-vertical-padding;
        bottom: $sidebar-horizontal-padding;
    }
    @include flex-column;
    @include transition-all();
    $self: &;
    &_opened {
        width: $sidebar-opened-width;
    }
    &__header {
        @include sidebar-padding;
    }
    &__content {
        @include sidebar-padding;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__logo-container {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    &__divider {
        @include sidebar-padding;
        margin-bottom: $menu-item-bottom-spacing * 2;
        width: 100%;
        &-element {
            border-bottom: $sidebar-divider;
            width: 100%;
        }
    }
    &__footer {
        @include sidebar-padding;
        padding: {
            top: $menu-item-bottom-spacing;
        }
        width: 100%;
    }
}