@import "../../styles/common";

.settingsGroup {
    &__btnIcon {
        margin-right: 10px;
    }

    &__buttons {
        padding-top: 10px;
    }

    &__btn:not(:last-child) {
        margin-right: 16px;
    }

    :global(.form-accordion__body) {
        & > * {
            margin-bottom: 24px;
        }
    }
}