@import "../../styles/common";

$toolbar-item-font-size: $ct-font-size-base * 1.14;

.article-toolbar {
  display: flex;

  &__item {
    @include article-action();
    font-size: $toolbar-item-font-size;
    padding: 10px 0;
    margin-right: 30px;
    color: $knowledge-base-main-text;

    &:hover {
      text-decoration: none;
      color: $knowledge-base-primary-text;
    }
  }

  &__icon {
    margin-right: 10px;
  }
}