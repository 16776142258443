@import "../../styles/common";

$validatable-input-placeholder-color: $ct-gray-650;

$validatable-input-alert-width: 1em;
$validatable-input-alert-right: calc(#{$input-padding-y} + #{$input-padding-x} / 4);
$validatable-input-alert-top: calc(50% - #{$validatable-input-alert-width} / 2);

.validatable-input {
  position: relative;
  $self: &;

  &_invalid {
    #{$self}__alert {
      visibility: visible;
      color: $form-feedback-icon-invalid-color;
    }
  }

  &_with-alert {
    .form-control {
      &.is-invalid {
        background: none;
      }
    }
  }

  &__alert {
    visibility: hidden;
    width: $validatable-input-alert-width;
    position: absolute;
    top: $validatable-input-alert-top;
    right: $validatable-input-alert-right;
  }

  &__delete-icon {
    @include closeIconControl;
    position: absolute;
    font-size: 22px;
    top: -5px;
    right: 5px;
  }

  .form-label > svg {
   @include input-info-icon;
  }

  .select-input.is-invalid {
    background-position: right calc(1.2em + .1875rem) center;
  }

  .form-control::placeholder {
    color: $validatable-input-placeholder-color;
  }
}