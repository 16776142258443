@use "../../styles/common" as common;

$disabled-bg: common.$gray-200;

.codeBlockValidatableInput {
  #{&}__control {
    @include common.mini-scrollbar;
    overflow-y: auto;
    height: 200px;
    background-color: common.$body-bg;
    border: 1px solid common.$input-border-color;
    border-radius: 0.25rem;
    font-size: inherit;
    transition: border-color 0.15s ease-in-out;
    margin: 0;
    padding: 0;

    &_invalid {
      border-color: common.$danger;
    }

    &_disabled {
      background-color: $disabled-bg;
    }
  }
}