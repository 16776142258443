@import "../../styles/common";

$article-comments-view-vertical-spacing: 20px;

.article-comments-view {
  @include article-section;
  margin-top: $article-comments-view-vertical-spacing;
  $self: &;
  &__input-container {
    margin-bottom: $article-comments-view-vertical-spacing;
  }
  &_loader {
    #{$self}__input-container .skeleton {
      width: 25%;
      height: 20px;
    }
  }
}