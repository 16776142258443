@use "../../styles/common";

$message-input-vertical-spacing: 20px;

.messageInput {
  &__user {
    margin-bottom: $message-input-vertical-spacing;
  }
  &__control {

  }
  &__buttonGroup {
    margin-top: $message-input-vertical-spacing;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &__submit {
    margin-left: 10px;
  }
}