@import "../../../styles/common";

.classifier-type-form {
  @include page-layout-sidebar();
  @include sidebar-form();

  &__content {
    padding: 10px 22px;
  }

  &__icon {
    font-size: 10px;
    height: 100%;
    width: 100%;
  }
}