@import "../../../styles/common";

$classifier-card-width: 275px;
$classifier-card-height: 125px;

$classifier-logo-icon-size: 36px;
$classifier-card-content-width: 200px;
$classifier-card-tag-color: $ct-blue-10;
$classifier-card-header-color: $ct-blue-11;

.classifier-list {
  &__card {
    width: $classifier-card-width;
    height: $classifier-card-height;
  }

  &__card-icon-container {
    width: $classifier-logo-icon-size;
    height: $classifier-logo-icon-size;
    display: flex;
    justify-content: center;
    align-self: flex-start;
  }

  &__card-icon {
    width: 100%;
    height: 100%;
    padding-top: 2px;
  }

  &__card-tags {
    display: flex;
    max-height: 20px;
    overflow: hidden;
    flex-wrap: wrap;
  }

  &__card-tag {
    @include text-truncate;
    padding: 2px 8px;
    background-color: $classifier-card-tag-color;
    border-radius: 4px;
    margin-left: 5px;
    max-width: $classifier-card-content-width;
  }

  .info-card {
    &__header {
      background-color: $classifier-card-header-color;
    }

    &__content {
      max-width: $classifier-card-content-width;
    }

    &__body {
      padding-bottom: 0;
    }

    &__type {
      @include text-truncate;
      margin-bottom: 10px;
    }
  }
}
