@use "../../styles/common";

.logo {
    &_clickable {
        cursor: pointer;
    }
    &__svg {
        height: 28px;
    }
    &__mainColor {
        fill: common.$secondary;
    }
    &__secondaryColor {
        fill: common.$body-color;
    }
    &__transparent {
        fill: common.$body-bg;
    }
}