@import "../../styles/common";

$article-border-color: $ct-gray-300;
$modification-date-font-size: $ct-font-size-base * 0.86;
$highlight-color: $ct-yellow-2;

.searchQueryArticle {
  padding: 15px 0;

  border-bottom: 1px solid $article-border-color;

  &:hover {
    cursor: pointer;
  }

  &__header {
    margin-bottom: 8px;
  }

  &__body {
    color: $knowledge-base-sub-text;
    margin-bottom: 10px;
    word-break: break-word;
    max-height: 150px;
    overflow-y: hidden;
  }

  &__header, &__body {
    em {
      background-color: $highlight-color;
      padding: 2px 0;
      font-style: normal;
    }
  }

  &__footer {
    display: flex;
    padding: 5px 0;
  }

  &__modificationDate {
    margin-left: auto;
    font-size: $modification-date-font-size;
    color: $knowledge-base-sub-text;
  }
}