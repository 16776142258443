@import "../../styles/common";

.project-user-form {
    @include page-layout-sidebar();

    &__card {
        @include section-border;
        padding-bottom: 20px;
    }

    .user-info {
        &__section {
            margin-bottom: 20px;
        }

        &__value {
            margin-left: 16px;
        }
    }

    .role-selector {
        margin-top: 1rem;
    }
}

