@use "../../../styles/common" as common;

.editSurveyField {
  @include common.card-border;
  border-radius: 5px;
  padding: 16px;

  &__slot {
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 14px;
  }

  &__saveBtn {
    margin-left: 14px;
  }
}