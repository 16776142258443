@import "../../styles/common";

.article {
  &__header {
    @include article-section;
    padding-top: 70px;
  }

  &__header-section {
    display: flex;
    align-items: center;

    &:first-child {
      margin-bottom: 20px;
    }
  }

  &__header-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
  }

  &__header-top {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-start;
  }

  &__title {
    display: flex;
    flex: 1;
    font-size: $knowledge-base-article-title-font-size;
    font-weight: bold;
    margin-right: 20px;
  }

  &__parameters {
    @include article-section;
    margin-bottom: 40px;
  }

  &__body {
    @include article-section;
    display: flex;
    font-size: $knowledge-base-article-text-font-size;
    word-break: break-word;
  }
}
