@import "../../../../styles/common";

$message-node-header-bg-color: $primary;
$message-node-width: 414px;

$add-btn-icon-color: $ct-gray-600;

.react-flow {
  .message-node {
    width: $message-node-width;
    border-radius: 0 20px 20px 20px;

    &__header {
      border-radius: 0 20px 0 0;
      color: $white;
      background-color: $message-node-header-bg-color;

      .btn-close {
        color: $white;
      }
    }

    &__content {
      background-color: $body-bg;
      padding: 16px 48px 10px;
      box-shadow: 0 10px 2px -2px transparentize($body-bg, 0.5);
    }

    &__text {
      @include mini-scrollbar();
      @include transition-all();

      width: 100%;
      border: none;
      resize: none;
      outline: none;
      line-height: 16px;

      &_invalid {
        @include invalid-control;
      }

      &:disabled {
        background-color: inherit;
      }
    }

    &__handle {
      @include scenario-handle;
      visibility: initial;

      &_default {
        bottom: 20px;
        top: auto;
      }
    }

    &__add-section {
      padding: 0 48px 16px;
    }

    &__add-btn {
      padding: 0;
      color: $add-btn-icon-color;
      width: 100%;
      border: none;
      border-radius: 5px;
      transition: $transition-fade;

      &:disabled {
        visibility: hidden;
      }
    }

    &__btn-list {
      padding-top: 8px;
    }
  }
}

.message-node {
  &__handle {
    visibility: hidden;
  }
}
