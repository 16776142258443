@import "../../styles/common";

$operator-status-item-font-size-base: $menu-item-title-font-size;
$operator-status-default-color: $body-color;

.operator-status-selector {
  margin-bottom: $menu-item-bottom-spacing * 2;
  $self: &;
  &.sidebar-context-menu {
    background-color: transparent;
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }
  &__container {
    align-items: center;
    justify-content: center;
  }
  &__item {
    display: flex;
    align-items: center;
    font-size: $operator-status-item-font-size-base;
    #{$self}__circle {
      @include set-operator-status-color($operator-status-default-color);
    }
    @each $status, $value in $operator-status-colors {
      &_#{$status} {
        #{$self}__circle {
          @include set-operator-status-color($value);
        }
      }
    }
  }
  &__selected-icon {
    margin-left: 5px;
  }
  &__circle {
    padding: 4px;
    border-radius: 50%;
  }
  &__context {
    &.context-menu {
      padding: 12px;
    }
  }
}