.routeTaskForm {
    padding: 10px;

    &__options {}

    &__buttons {
        display: flex;
        justify-content: flex-end;
    }

    &__button {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }
}