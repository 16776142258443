@use "../../../styles/common";

.removableListItem {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__deleteIcon {
    @include common.closeIconControl;
    font-size: 20px;
  }

  &:last-of-type {
    padding-bottom: 1rem;
  }
}
