@import "../../styles/common";

$channel-scenario-editor-height: 85vh;

.channel-scenario {
  @include tab-page;
  height: 100%;
  overflow: visible;

  &__editor {
    height: $channel-scenario-editor-height;
  }

  &__loader {
    height: $channel-scenario-editor-height;
  }
}
