@import "../../styles/common";

$category-action-size: 14px;

.category-action {
  display: none;
  padding: 5px;
  $self: &;

  &__icon, &__spinner {
    color: $knowledge-base-sub-text
  }

  &__spinner {
    width: $category-action-size;
    height: $category-action-size;
  }

  &:hover {
    #{$self}__icon {
      color: $knowledge-base-primary-text
    }
  }
}
