@import "../../../styles/common";

$classifier-status-icon-size: 8px;

.classifier-status-icon {
  &::before {
    content: '';
    display: inline-block;
    width: $classifier-status-icon-size;
    height: $classifier-status-icon-size;
    border-radius: 50%;
    margin-right: 6px;
  }

  &_starting, &_startingrequested {
    &::before {
      background-color: $ct-yellow-1;
    }
  }

  &_loading {
    &::before {
      background-color: $ct-orange-3;
    }
  }

  &_training {
    &::before {
      background-color: $ct-green-7;
    }
  }

  &_active, &_done {
    &::before {
      background-color: $ct-green-8;
    }
  }

  &_stopped, &_startingfailed, &_loadingfailed, &_trainingfailed, &_metricsfailed, &_donefailed {
    &::before {
      background-color: $ct-red-4;
    }
  }

  &_metrics {
    &::before {
      background-color: $ct-blue-9;
    }
  }
}