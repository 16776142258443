@use "../../../styles/common";

.formControlWithAdd {
  &__addSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  &__addButton {
    padding: 8px 4px;
  }
}