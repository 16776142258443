@use "../../styles/common" as common;

$article-comment-bottom-border-color: common.$ct-gray-200;

.articleComment {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $article-comment-bottom-border-color;
  $self: &;
  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__date {
    color: common.$text-muted;
    flex: 1 1;
  }
  &__user {
    flex: 1 1;
  }
  &__body {
    margin-top: 16px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
  &_loader {
    :global(.skeleton) {
      height: 20px;
    }
    #{$self}__user {
      display: flex;
      flex-direction: row;
      align-items: center;
      :global(.skeleton:first-child) {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        margin-right: 10px;
      }
      :global(.skeleton:last-child) {
        width: 30%;
      }
    }
    #{$self}__date :global(.skeleton) {
      width: 30%;
    }
    #{$self}__body :global(.skeleton) {
      height: 70px;
    }
    #{$self}__bottom :global(.skeleton) {
      height: 16px;
      width: 40px;
    }
  }
}