@import "../../styles/common";

$page-layout-left-sidebar-max-width: 450px;
$page-layout-left-sidebar-min-width: $sidebar-width;
$page-layout-width: $sidebar-right-width;
$page-layout-left-sidebar-title-color: $ct-black-50;

.page-layout {
    display: flex;
}

.page-layout-content {
    flex: 1;
    height: 100vh;
    @include transition-all();
    overflow-y: auto;

    &_full {
        margin-right: $sidebar-right-width;
    }
}

.page-layout-left-sidebar {
    @include flex-column;
    height: 100vh;
    max-width: $page-layout-left-sidebar-max-width;
    min-width: $page-layout-left-sidebar-min-width;
    overflow: hidden;
    border-right: $sidebar-divider;

    &__title {
        margin-right: auto;
        color: $page-layout-left-sidebar-title-color;
        font-size: 24px;
    }

    &__actions {
        >*:not(:last-child) {
            margin-right: 10px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 40px 15px 30px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow-y: auto;

        &_not-scrollable {
            overflow-y: hidden;
        }
    }
}

.page-layout-sidebar {
    &_extended {
        width: 670px;
    }
}
