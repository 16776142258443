@import "../../styles/common";

$bg-color-btn: $body-bg;
$border-color-btn: $input-border-color;

.validatable-input-with-control {
  .btn-outline-secondary, .show > .btn-outline-secondary {
    @include no-outline-focus;
    @include button-variant($bg-color-btn, $border-color-btn, $bg-color-btn, $border-color-btn, $bg-color-btn);
  }

  .dropdown-menu {
    min-width: 6.6rem;
    max-height: 200px;
    overflow-y: auto;

    .dropdown-item {
      padding-left: 1rem;
    }
  }

  .dropdown-toggle {
    &::after {
      position: absolute;
      right: 14px;
    }
  }

  .input-group-text {
    background-color: transparent;
  }

  .btn {
    outline: none;
    padding: calc(0.375rem - 1px) calc(0.75rem - 1px);
    width: 106px;
    display: flex;
    align-items: center;
  }
}