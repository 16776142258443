@use "../../styles/common";

$operator-status-item-font-size-base: common.$menu-item-title-font-size;
$operator-status-default-color: common.$body-color;

.operatorStatus {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  color: common.$white;
  font-size: $operator-status-item-font-size-base;
  white-space: nowrap;
  overflow: hidden;
  @include common.transition();
  @include common.set-operator-status-color($operator-status-default-color);
  @each $status, $value in common.$operator-status-colors {
    &_#{$status} {
      @include common.set-operator-status-color($value);
    }
  }
  &__title {

  }
}