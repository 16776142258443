$agent-card-icon-size: 25px;

.agent-list {
  &__card {
    height: 110px;
    width: 230px;
  }
}

.agent-card {
  &__icon {
    width: $agent-card-icon-size;
    height: $agent-card-icon-size;
    display: flex;
    justify-content: center;
    margin-top: -5px;
    font-size: 1.2rem;
    padding-top: 10px;
  }
}
